import React, { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

// Componentes

// Material ui - style
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { SellerController } from "../../../services/controllers/global";
import { useMerchant } from "../../../store/global/useMerchant";

// Material ui - core
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
  Grid,
  Button,
  TextField,
} from '@material-ui/core'

// Material ui - lab
import Skeleton from '@material-ui/lab/Skeleton'

// Material ui - icones
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CommissionSetting from '../CommissionSetting'
import BasePage from '../../../pages/basePage'
import ModalType from '../../atoms/Modal'
import API from '../../../services/api'
import { Action } from '../../ui/Action'
import { Plus } from 'lucide-react'
import { SellerRegistrationAlert } from './alerts/SellerRegistration-alert'
import { SellerEditAlert } from './alerts/SellerEdit-alert'
import { Alert } from '../../ui/Alert'
import { UserRoundPen, Trash2 } from 'lucide-react'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    marginBottom: '20px',
    marginTop: '20px',
    border: '1px solid #ccd219',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    padding: '11px',
  },
  table: {
    minWidth: 300,
    border: '1px solid #ccc',
  },
  status: {
    display: 'flex',
    maxWidth: '200px',
    marginTop: '20px',
    alignItems: 'center',
    marginBottom: '20px',
  },
  department: {
    display: 'flex',
    maxWidth: '300px',
    marginTop: '20px',
    alignItems: 'center',
    marginBottom: '20px',
  },
  synchronize: {
    marginBottom: '10px',
  },
}))

const CustomButton = withStyles(() => ({
  root: {
    color: '#fff',
    border: '0',
    backgroundColor: '#ccd219',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#a0a510',
      border: 0,
      color: '#fff',
    },
  },
}))(Button)

const ITEM_HEIGHT = 48

function Employee({ prof, accessProfile, stateValue, getProf, getAccess }) {

  const merchant = useMerchant((st) => st.data.merchant)

  const history = useHistory()
  const motorista = history.location.search.search('motorista') >= 0

  const [value, setValue] = useState(motorista ? '4' : '0')
  const handleChangeIndex = (event, value) => setValue(value)
  const { control } = useForm()

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(null)

  const [sellers, setSellers] = React.useState(null)
  const [openModal, setOpenModal] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [disabledButton, setDisabledButton] = React.useState(null)
  const [profissional, setProfissional] = React.useState(prof)
  const [friendly, setFriendly] = useState(false)


  const closeModal = () => {
    setOpenModal(false)
    setFriendly(false)
  }

  const postActive = async (id, active) => {
    try {
      const data = await API.post(`employee/${id}/status`, {
        active,
      })
      data?.status === 201 && getProf()
      setFriendly(false)
      setError(false)
    } catch (err) {
      setFriendly(err?.response?.data?.message)

      console.log('Erro:', err)
    }
  }
  const onDecrement = (key) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(key)
  }

  const postActiveAccess = async (id, active) => {
    try {
      const data = await API.post(`employee/${id}/status`, {
        active,
      })
      data?.status === 201 && getAccess()
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const getSellers = async () => {
    try {
      const { data } = await API.get('employees/sellers/search')
      setSellers(data?.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const CSeller = SellerController(getSellers)

  const handleClose = (id, active) => {
    setAnchorEl(null)
    setOpen(false)
    postActive(id, active)
  }

  const handleCloseAccess = (id, active) => {
    setAnchorEl(null)
    setOpen(false)
    postActiveAccess(id, active)
  }

  const postIntegrateSellers = async () => {
    setDisabledButton(true)
    try {
      const result = await API.post('jobs/integrate-vo-sellers')
      setOpenModal(result?.data.message)
      if (result?.status === 201) {
        getSellers()
        setDisabledButton(false)
      }
    } catch (err) {
      setOpenModal(err?.response?.data?.friendly_message)
      setError(true)
    }
  }

  const handleStatus = (value) => {
    getProf(value)
  }
  const getDrivers = (value) => {
    getProf(value, true)
  }

  const handleDepartament = (value) => {
    if (value === 'allStatus') {
      setProfissional(prof)
    } else {
      setProfissional(prof?.filter((item) => item?.department === value))
    }
  }

  React.useEffect(() => {
    getSellers()
  }, [])

  React.useEffect(() => {
    setProfissional(prof)
  }, [prof])

  React.useEffect(() => {
    if (value === '4') {
      getDrivers()
    }
  }, [value, getDrivers])

  return (
    <BasePage
      pageTitle={
        (parseInt(value, 10) === 0 && 'Profissionais') ||
        (parseInt(value, 10) === 1 && 'Perfil de Acesso') ||
        (parseInt(value, 10) === 2 && 'Gestão de Comissão') ||
        (parseInt(value, 10) === 3 && 'Vendedores')
      }
    >
      <ModalType
        open={(openModal && true) || (friendly && true)}
        close={closeModal}
        titleDescription={openModal || friendly}
        err={error}
        verification={false}
      />

      {/* <Modal
        open={openModalType}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal> */}

      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeIndex}
        aria-label="disabled tabs example"
        style={{
          boxShadow: '0 6px 20px rgb(0 0 0 / 10%)',
          borderRadius: '7px',
        }}
      >
        <Tab label="PROFISSIONAIS" value="0" />
        {/* <Tab label="PERFIL DE ACESSO" value="1" /> */}
        <Tab label="COMISSÃO" value="2" />
        <Tab label="VENDEDORES" value="3" />
        <Tab label="MOTORISTAS" value="4" />
      </Tabs>
      { }
      {parseInt(value, 10) === 0 && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Paper component="form" className={classes.root} elevation={0}>
                <InputBase
                  className={classes.input}
                  placeholder="Nome, CPF, Telefone"
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Controller
                control={control}
                defaultValue="allStatus"
                name="status"
                as={({ onChange, value }) => (
                  <TextField
                    fullWidth
                    className={classes.status}
                    select
                    value={value}
                    label="Status"
                    variant="outlined"
                    defaultChecked="ATIVO"
                    onChange={(e) => {
                      onChange(e.target.value)
                      handleStatus(e.target.value)
                    }}
                  >
                    <MenuItem key="allStatus" value="ATIVO">
                      Todos os Status
                    </MenuItem>

                    <MenuItem key="ATIVO" value="ATIVO">
                      Ativos
                    </MenuItem>

                    <MenuItem key="INATIVO" value="INATIVO">
                      Inativos
                    </MenuItem>
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                control={control}
                defaultValue="allStatus"
                name="departament"
                as={({ onChange, value }) => (
                  <TextField
                    fullWidth
                    className={classes.department}
                    select
                    value={value}
                    label="Departamento"
                    variant="outlined"
                    defaultChecked="allStatus"
                    onChange={(e) => {
                      onChange(e.target.value)
                      handleDepartament(e.target.value)
                    }}
                  >
                    <MenuItem key="allStatus" value="allStatus">
                      Todos os Departamentos
                    </MenuItem>

                    <MenuItem key="Banho e Tosa" value="Banho e Tosa">
                      Banho e Tosa
                    </MenuItem>

                    <MenuItem
                      key="Clínica Veterinária"
                      value="Clínica Veterinária"
                    >
                      Clínica Veterinária
                    </MenuItem>
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={3}>
              <CustomButton
                onClick={() => {
                  stateValue(1)
                }}
                fullWidth
                type="submit"
                color="secondary"
                size="large"
                justify="center"
                alignItems="center"
              >
                Novo Profissional
              </CustomButton>
            </Grid>
          </Grid>

          <TableContainer component={Paper} elevation={0}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Nome
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    Perfil de acesso
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    Departamento
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    E-mail
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    Status
                  </TableCell>
                  <TableCell style={{ width: 60 }} align="right" />
                </TableRow>
              </TableHead>

              {/* SKELETON */}

              {profissional ? (
                <TableBody>
                  {profissional?.map((row) => (
                    <TableRow key={row.email}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        {row.group}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        {row?.department}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        {row.email}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        {row.status}
                      </TableCell>
                      <TableCell style={{ width: 60 }} align="right">
                        <>
                          <IconButton
                            aria-label="more"
                            aria-controls={row.employeeId}
                            aria-haspopup="true"
                            onClick={onDecrement(row.employeeId)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id={row.employeeId}
                            anchorEl={anchorEl}
                            keepMounted
                            open={row.employeeId === open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <MenuItem
                              key="ativo"
                              onClick={() => handleClose(row.employeeId, true)}
                            >
                              Ativo
                            </MenuItem>
                            <MenuItem
                              key="inativo"
                              onClick={() => handleClose(row.employeeId, false)}
                            >
                              Inativo
                            </MenuItem>
                            <MenuItem
                              key="editar"
                              onClick={() =>
                                history.push(
                                  `/profissionais/editar/${row.employeeId}`,
                                  { id: row.employeeId },
                                )
                              }
                            >
                              Editar
                            </MenuItem>
                          </Menu>
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      )}
      {parseInt(value, 10) === 1 && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <Paper component="form" className={classes.root} elevation={0}>
                <InputBase
                  className={classes.input}
                  placeholder="Nome, CPF, Telefone"
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <CustomButton
                onClick={() => stateValue(2)}
                fullWidth
                type="submit"
                color="secondary"
                size="large"
                justify="center"
                alignItems="center"
              >
                Novo Perfil de Acesso
              </CustomButton>
            </Grid>
          </Grid>

          <TableContainer component={Paper} elevation={0}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Nome
                  </TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell style={{ width: 160 }} align="right">
                    Status
                  </TableCell>
                  <TableCell style={{ width: 60 }} align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {accessProfile?.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {row.status}
                    </TableCell>
                    <TableCell style={{ width: 60 }} align="right">
                      <>
                        <IconButton
                          aria-label="more"
                          aria-controls={row.id}
                          aria-haspopup="true"
                          onClick={onDecrement(row.id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id={row.id}
                          anchorEl={anchorEl}
                          keepMounted
                          open={row.id === open}
                          onClose={handleCloseAccess}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: '20ch',
                            },
                          }}
                        >
                          <MenuItem
                            key="ativo"
                            onClick={() => handleCloseAccess(row.id, true)}
                          >
                            Ativo
                          </MenuItem>
                          <MenuItem
                            key="inativo"
                            onClick={() => handleCloseAccess(row.id, false)}
                          >
                            Inativo
                          </MenuItem>
                        </Menu>
                      </>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {parseInt(value, 10) === 2 && <CommissionSetting />}

      {parseInt(value, 10) === 3 && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={9} />
            <Grid item xs={3} className={classes.synchronize}>
              {!!merchant.hasTokenVarejo && (
                <CustomButton
                  onClick={() => postIntegrateSellers()}
                  fullWidth
                  disabled={disabledButton}
                  type="submit"
                  color="secondary"
                  size="large"
                  justify="center"
                  alignItems="center"
                >
                  SINCRONIZAR
                </CustomButton>
              )}
              {!merchant.hasTokenVarejo && (
                <div className='flex justify-end pt-4'>
                  <Action.Root
                    type="button"
                    name={`change-credt-card`}
                    onClick={CSeller.newSeller}
                  >
                    <Action.Icon icon={Plus} size={22} />
                    Novo vendedor
                  </Action.Root>
                </div>
              )}

            </Grid>
          </Grid>
          <TableContainer component={Paper} elevation={3}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell align="center">Função</TableCell>
                  <TableCell align="center">Status</TableCell>
                  {!merchant.hasTokenVarejo && <TableCell align="center"></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {sellers?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.jobTitle}</TableCell>
                    <TableCell align="center">{row.active}</TableCell>
                    {!merchant.hasTokenVarejo && (
                      <TableCell align="center" width={110}>
                        <div className='flex gap-2'>
                          <Action.Root
                            outline
                            type="button"
                            title="Editar vendedor"
                            name={`edit-seller-${row.id}`}
                            onClick={() => CSeller.editSeller({
                              id: row.id,
                              status: true,
                              name: row.name,
                            })}
                          >
                            <Action.Icon icon={UserRoundPen} size={22} />
                          </Action.Root>
                          <Action.Root
                            status="danger"
                            type="button"
                            title="Deletar vendedor"
                            name={`disable-seller-${row.id}`}
                            onClick={() => CSeller.disableSeller(row.id)}
                          >
                            <Action.Icon icon={Trash2} size={22} />
                          </Action.Root>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <SellerRegistrationAlert />
          <SellerEditAlert />
        </>
      )}
      {parseInt(value, 10) === 4 && (
        <>
          <Grid
            container
            spacing={3}
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              marginBottom: '20px',
            }}
          >
            <Grid item xs={3}>
              <CustomButton
                onClick={() => {
                  stateValue(3)
                }}
                fullWidth
                type="submit"
                color="secondary"
                size="large"
                justify="center"
                alignItems="center"
              >
                Novo Motorista
              </CustomButton>
            </Grid>
          </Grid>

          <TableContainer component={Paper} elevation={0}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Nome
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    Perfil de acesso
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    Departamento
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    E-mail
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    Status
                  </TableCell>
                  <TableCell style={{ width: 60 }} align="right" />
                </TableRow>
              </TableHead>

              {/* SKELETON */}

              {profissional ? (
                <TableBody>
                  {profissional?.map((row) => (
                    <TableRow key={row.email}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        {row.group}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        {row?.department}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        {row.email}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        {row.status}
                      </TableCell>
                      <TableCell style={{ width: 60 }} align="right">
                        <>
                          <IconButton
                            aria-label="more"
                            aria-controls={row.employeeId}
                            aria-haspopup="true"
                            onClick={onDecrement(row.employeeId)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id={row.employeeId}
                            anchorEl={anchorEl}
                            keepMounted
                            open={row.employeeId === open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <MenuItem
                              key="ativo"
                              onClick={() => handleClose(row.employeeId, true)}
                            >
                              Ativo
                            </MenuItem>
                            <MenuItem
                              key="inativo"
                              onClick={() => handleClose(row.employeeId, false)}
                            >
                              Inativo
                            </MenuItem>
                            <MenuItem
                              key="editar"
                              onClick={() =>
                                history.push(
                                  `/profissionais/editar/${row.employeeId}`,
                                  { id: row.employeeId },
                                )
                              }
                            >
                              Editar
                            </MenuItem>
                          </Menu>
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      )}
    </BasePage>
  )
}

export default Employee
