import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  service: false,
};

export const useService = create((set) => ({
  data: defaultData,
  actions: {
    setService: (service = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!service) return;

          draft.data.service = service;
        })
      ),

    updateService: (values = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!values) return;

          Object.keys(values).forEach((key) => {
            draft.data.service[key] = values[key];
          });
        })
      ),
  },
}));
