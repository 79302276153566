import _ from "lodash";
import { apiSimple } from "../../api";

import { toast } from "react-toastify";
import { useSeller } from "../../../store/global/useSeller";
import { Alert } from "../../../components/ui/Alert";

export default class SellerController {
  _default = {
    name: "",
    status: true,
  };
  _refreshSellers = null

  alertActions = Alert.store.getState().actions

  setSeller = useSeller.getState().actions.setSeller;
  updateSeller = useSeller.getState().actions.updateSeller;

  constructor(refreshSellers) {
    this._refreshSellers = refreshSellers
    this.setSeller(this._default);
  }

  newSeller = () => {
    this.setSeller({ name: '', status: true });
    this.alertActions.isOpen('seller-registration-alert', true)
  }

  editSeller = (seller) => {
    this.setSeller(seller);
    this.alertActions.isOpen('seller-edit-alert', true)
  }

  setName = (name) => {
    this.updateSeller({ name })
  }

  setStatus = (status) => {
    this.updateSeller({ status })
  }

  closeAlert = () => {
    this.alertActions.isOpen('seller-registration-alert', false)
    this.alertActions.isOpen('seller-edit-alert', false)
  }


  validate = () => {
    const { name } = useSeller.getState().data.seller
    if (!name) {
      toast.error("Nome é obrigatório")
      return false
    }

    return true
  }

  register = async () => {
    if (!this.validate()) return
    const seller = useSeller.getState().data.seller

    try {
      await apiSimple.post('affiliate-store/seller', seller)

      toast.success("Vendedor cadastrado com sucesso")
      this._refreshSellers?.()
      this.alertActions.isOpen('seller-registration-alert', false)
    } catch (err) {
      toast.error("Erro ao cadastrar vendedor")
      console.log(err)
    }
  };

  update = async () => {
    if (!this.validate()) return
    const seller = useSeller.getState().data.seller

    try {
      await apiSimple.put(`affiliate-store/seller/${seller.id}`, seller)

      toast.success("Vendedor atualizado com sucesso")
      this._refreshSellers?.()
      this.alertActions.isOpen('seller-edit-alert', false)
    } catch (err) {
      toast.error("Erro ao editar vendedor")
      console.log(err)
    }
  };

  disableSeller = async (sellerId) => {
    try {
      await apiSimple.put(`affiliate-store/seller/${sellerId}`, { status: false })

      toast.success("Vendedor desativado com sucesso")
      this._refreshSellers?.()
    } catch (err) {
      toast.error("Erro ao desativado vendedor")
      console.log(err)
    }
  };
}
