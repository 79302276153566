import { Eye, CreditCard, XCircle } from 'lucide-react'


import { SubscriberController } from '../../../../../services/controllers/subscription';

import { Action } from '../../../../../components/ui/Action'

import { Container } from './styles'
import { Pause } from 'lucide-react';
import { useMerchant } from '../../../../../store/global/useMerchant';
import _ from 'lodash';

export function SubscriberActions({ subscriber }) {
  const merchant = useMerchant(st => st.data.merchant)
  const CSubscriber = SubscriberController()

  return (
    <Container>
      <Action.Root
        status="400"
        type="button"
        title="Ver Detalhes"
        name={`see-detail-${subscriber.id}`}
        onClick={() => CSubscriber.showDetails(subscriber)}
      >
        <Action.Icon icon={Eye} size={22} />
      </Action.Root>
      <Action.Root
        outline
        status="600"
        type="button"
        title="Trocar Cartão"
        name={`change-credt-card-${subscriber.id}`}
        disabled={subscriber.isCanceled}
        onClick={() => CSubscriber.changeCreditCardSubscribers(subscriber)}
      >
        <Action.Icon icon={CreditCard} size={22} />
      </Action.Root>
      {_.some(merchant.plans, { id: 'black' }) && (
        <Action.Root
          status="warning"
          type="button"
          title="Pausar Assinatura"
          name={`pause-subscription-${subscriber.id}`}
          disabled={subscriber.isCanceled || subscriber.isPaused || subscriber.plan.type !== "Black"}
          onClick={() => CSubscriber.pauseSubscribers(subscriber)}
        >
          <Action.Icon icon={Pause} size={22} />
        </Action.Root>
      )}
      <Action.Root
        outline
        status="danger"
        type="button"
        title="Cancelar Assinatura"
        name={`cancel-subscription-${subscriber.id}`}
        disabled={subscriber.isCanceled || subscriber.isPaused}
        onClick={() => CSubscriber.cancelSubscribers(subscriber)}
      >
        <Action.Icon icon={XCircle} size={22} />
      </Action.Root>
    </Container>
  )
}
