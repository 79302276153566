import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { useMerchant } from "../../../../store/global/useMerchant";
import { useCallback, useContext, useEffect, useState } from "react";
import API from '../../../../services/api'
import { formatName } from "../../../../services/functions";
import { AuthContext } from "../../../../providers/auth";

export function AccordionPurchaseHistory(props) {
  const { modules } = useContext(AuthContext);

  const [history, setHistory] = useState([])
  const merchant = useMerchant(st => st.data.merchant)

  const getPurchaseHistory = useCallback(async () => {
    const { data } = await API.get(`/crm/cliente/historico-compras?storeid=${merchant.id}&cpf=${props.cpf}`)
    setHistory(data.historicoCompras)
  }, [modules])

  useEffect(() => {
    getPurchaseHistory()
  }, [])


  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>Histórico de compras</Typography>
        <span className="ml-auto bg-scale-500 mr-2 rounded-md leading-none px-2 py-1 h-6 text-white flex items-center justify-center">novo</span>
      </AccordionSummary>
      <AccordionDetails>
        <ul className="flex flex-col w-full">
          {history.map((item) => (
            <li key={item.id} class="flex w-full gap-3 border-b-2 border-zinc-100 px-2 py-4 first:pt-0 last:pb-0 last:border-none">
              <div class="h-24 w-24 overflow-hidden rounded-md border-2 border-zinc-300 bg-white p-3">
                <img alt={item.title} src={item.link} class="h-full w-full object-contain" />
              </div>
              <div class="flex flex-1 flex-col gap-1">
                <p class="text-base text-zinc-600">
                  <strong class="font-bold">{formatName(item.title)}</strong>
                </p>
                <p class="text-sm text-zinc-600">
                  <strong class="mr-1 font-bold text-zinc-600">Última compra:</strong>
                  <span class="capitalize">{item.lastPurshace}</span>
                </p>
                <p class="flex flex-col text-sm text-zinc-600">
                  <strong class="mr-1 font-bold">Produto:</strong>{item.lastItem}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </AccordionDetails>
    </Accordion>

  )
}
