import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  seller: false,
};

export const useSeller = create((set) => ({
  data: defaultData,
  actions: {
    setSeller: (seller = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!seller) return;

          draft.data.seller = seller;
        })
      ),

    updateSeller: (values = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!values) return;

          Object.keys(values).forEach((key) => {
            draft.data.seller[key] = values[key];
          });
        })
      ),
  },
}));
