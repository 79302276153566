import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles'

function Log({ logdata }) {
  const [open, setOpen] = useState(false)
  const {
    item,
    itemDate,
    line,
    itemButton,
    button,
    downArrow,
    upArrow,
    acordion,
    openAcordion,
    link,
    textAcrodion,
    itemTag,
  } = styles()

  return (
    <div className={line}>
      <span
        className={itemTag}
        style={{
          background: logdata.updatedBy === '-' ? '#ccd219' : '#cf4e0e',
        }}
      >
        {logdata.updatedBy === '-' ? 'Sistema' : 'Manual'}
      </span>
      <span className={itemDate}>{logdata.logDate}</span>
      <span className={item}>{logdata.serviceName}</span>

      <span className={itemButton}>
        <button
          className={button}
          onClick={() => {
            setOpen(!open)
          }}
        >
          <em className={open ? upArrow : downArrow} />
        </button>
      </span>
      <div className={open ? openAcordion : acordion}>
        <p className={textAcrodion}>
          <strong>Alterado por:</strong>
          &nbsp;
          {logdata.updatedBy === '-' ? 'Sistema' : logdata.updatedBy}
        </p>
        <p className={textAcrodion}>
          <strong>Motivo:</strong>
          {logdata.motive}
        </p>
        <p className={textAcrodion}>
          <strong>Alteração:</strong>
          {logdata.update}
        </p>
        {logdata.appointment !== '-' && (
          <p className={textAcrodion}>
            <strong>Agendamento: </strong>
            <Link
              className={link}
              to={{
                pathname: `/banho-tosa/check-in/order/${logdata.appointment}?employeeId=${logdata.employeeId}`,
              }}
              target="_blank"
            >
              {logdata.appointment}
            </Link>
          </p>
        )}
      </div>
    </div>
  )
}

export default Log
