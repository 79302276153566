import _ from "lodash";
import { apiSimple } from "../../api";

import { toast } from "react-toastify";
import { useService } from "../../../store/global/useService";
import { Alert } from "../../../components/ui/Alert";

export default class ServiceController {
  _default = {
    price: 0,
    status: true,
  };
  _refreshServices = null

  alertActions = Alert.store.getState().actions

  setService = useService.getState().actions.setService;
  updateService = useService.getState().actions.updateService;

  constructor(refreshServices) {
    this._refreshServices = refreshServices
    this.setService(this._default);
  }

  newService = () => {
    this.setService({ price: 0, status: true, });
    this.alertActions.isOpen('service-registration-alert', true)
  }

  editService = (service) => {
    console.log(service)
    this.setService(service);
    this.alertActions.isOpen('service-edit-alert', true)
  }

  setPrice = (price) => {
    this.updateService({ price })
  }

  setStatus = (status) => {
    this.updateService({ status })
  }

  closeAlert = () => {
    this.alertActions.isOpen('service-registration-alert', false)
    this.alertActions.isOpen('service-edit-alert', false)
  }


  validate = () => {
    const { price } = useService.getState().data.service
    if (!price) {
      toast.error("O Valor é obrigatório")
      return false
    }

    return true
  }

  register = async () => {
    if (!this.validate()) return
    const service = useService.getState().data.service

    try {
      await apiSimple.post('affiliate-store/service', service)

      toast.success("Vendedor cadastrado com sucesso")
      this._refreshServices?.()
      this.alertActions.isOpen('service-registration-alert', false)
    } catch (err) {
      toast.error("Erro ao cadastrar vendedor")
      console.log(err)
    }
  };

  update = async () => {
    if (!this.validate()) return
    const service = useService.getState().data.service

    try {
      await apiSimple.put(`services/update/${service.id}`, service)

      toast.success("Serviço atualizado com sucesso")
      this._refreshServices?.()
      this.alertActions.isOpen('service-edit-alert', false)
    } catch (err) {
      toast.error("Erro ao editar Serviço")
      console.log(err)
    }
  }
}
