import { SellerController } from "../../../../services/controllers/global";
import { useSeller } from "../../../../store/global/useSeller";
import { Action } from "../../../ui/Action";

import { Alert } from "../../../ui/Alert";
import { Input } from "../../../ui/Form/Input";
import { Switch } from "../../../ui/Form/Switch";

export function SellerEditAlert() {
  const CSeller = SellerController()
  const seller = useSeller((st) => st.data.seller);

  return (
    <Alert.Root name="seller-edit-alert">
      <Alert.Content className="!max-w-xl">
        <Alert.Title>Editar Vendedor</Alert.Title>
        <div className="flex flex-wrap  items-center gap-5 mb-6">
          <div className="flex-1">
            <Input.Root label="Nome" onChange={CSeller.setName} value={seller.name} />
          </div>
        </div>
        <Alert.Actions>
          <Action.Root type="button" onClick={CSeller.closeAlert} outline status="danger">Cancelar</Action.Root>
          <Action.Root type="button" onClick={CSeller.update}>Salvar</Action.Root>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root >
  )
}
